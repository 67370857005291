import { combineReducers } from "@reduxjs/toolkit";
import content from "./layoutContentSlice";
import message from "./messageSlice";
import popup from "./popupSlice";

const reducer = combineReducers({
  content,
  message,
  popup,
});

export default reducer;
