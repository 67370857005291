import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "lib/redux";

export const selectAuthProfessionalLoginState = ({ auth }: RootState) =>
  auth.login.professional.state;

interface IInitialState {
  activeSection: "form" | "verifyEmail";
  loginForm: { email: string; password: string };
}

const initialState: IInitialState = {
  activeSection: "form",
  loginForm: { email: "", password: "" },
};

const loginStateSlice = createSlice({
  name: "auth/login/professional/state",
  initialState,
  reducers: {
    setActiveSection: (state, action: PayloadAction<IInitialState["activeSection"]>) => {
      state.activeSection = action.payload;
    },
    setLoginFormValues: (state, action: PayloadAction<IInitialState["loginForm"]>) => {
      state.loginForm = action.payload;
    },
  },
});

export const { setActiveSection, setLoginFormValues } = loginStateSlice.actions;

export default loginStateSlice.reducer;
