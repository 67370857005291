import { ButtonProps, Button as MuiButton } from "@mui/material";
import { useUpdatedStyles } from "hooks";
import clsx from "lib/clsx";
import { LoadingSpinner } from ".";

interface IProps extends ButtonProps {
  loading?: boolean;
}

const Button = ({ className, loading, disabled, variant, children, ...props }: IProps) => {
  const [ref, styles] = useUpdatedStyles();
  const fontSize = styles.fontSize;
  const isDisabled = disabled || loading;

  return (
    <MuiButton
      ref={ref}
      {...props}
      className={clsx(
        variant === "outlined" && "border-2 hover:border-2",
        variant === "text" && isDisabled && "pointer-events-none",
        className,
      )}
      disabled={variant !== "text" && isDisabled}
      variant={variant}
      startIcon={loading ? <LoadingSpinner size={fontSize} /> : props.startIcon}
    >
      <div className="w-full truncate text-nowrap text-center">{children}</div>
    </MuiButton>
  );
};

export default Button;
