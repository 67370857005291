import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorResult, isError } from "lib/axios/error";
import { RootState } from "lib/redux";
import { IProfessionalSettingsContent } from "models/dashboard/professional/settings";
import { HYDRATE } from "next-redux-wrapper";
import { getProfessionalSettingsContentReq } from "rest-api/user/settings";

export const getProfessionalSettingsContent = createAsyncThunk<
  typeof initialState,
  void,
  { rejectValue: ErrorResult }
>(
  "dashboard/professional/settings/content/getProfessionalSettingsContent",
  async (_, { rejectWithValue }) => {
    const result = await getProfessionalSettingsContentReq();

    if (isError(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const selectProfessionalSettingsContent = ({ dashboard }: RootState) =>
  dashboard.professional.settings.content;

const initialState: IProfessionalSettingsContent = {
  title: "",
  infoSection: {
    title: "",
    form: { fields: [], submitButtonText: "" },
  },
  securitySection: {
    title: "",
    form: { fields: [], submitButtonText: "" },
    links: [],
  },
  preferenceSection: {
    title: "",
    options: [],
  },
  accountSection: {
    title: "",
    content: "",
    buttonText: "",
  },
};

const contentSlice = createSlice({
  name: "dashboard/professional/settings/content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProfessionalSettingsContent.fulfilled, (_, action) => action.payload)
      .addCase(
        HYDRATE,
        (_, action: PayloadAction<IProfessionalSettingsContent, typeof HYDRATE>) => action.payload,
      );
  },
});

export default contentSlice.reducer;
