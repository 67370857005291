import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorResult, isError } from "lib/axios/error";
import { RootState } from "lib/redux";
import { IProfessionalLoginContent } from "models/auth";
import { HYDRATE } from "next-redux-wrapper";
import { getProfessionalLoginContentReq } from "rest-api/auth/professional-login";

export const getProfessionalLoginContent = createAsyncThunk<
  typeof initialState,
  void,
  { rejectValue: ErrorResult }
>("auth/login/professional/content/getProfessionalLoginContent", async (_, { rejectWithValue }) => {
  const result = await getProfessionalLoginContentReq();

  if (isError(result)) {
    return rejectWithValue(result);
  }

  return result;
});

export const selectAuthProfessionalLoginContent = ({ auth }: RootState) =>
  auth.login.professional.content;

const initialState: IProfessionalLoginContent = {
  title: "",
  form: { fields: [], submitButtonText: "" },
  verifyEmailSection: {
    title: "",
    content: "",
    form: { fields: [], submitButtonText: "" },
    resendCodeContent: "",
    resendCodeButtonText: "",
  },
  switchToRegisterText: "",
};

const contentSlice = createSlice({
  name: "auth/login/professional/content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProfessionalLoginContent.fulfilled, (_, action) => action.payload)
      .addCase(
        HYDRATE,
        (_, action: PayloadAction<IProfessionalLoginContent, typeof HYDRATE>) => action.payload,
      );
  },
});

export default contentSlice.reducer;
