import { CssVarsProvider, extendTheme } from "@mui/material/styles";
import { AuthProvider } from "components/auth";
import { LazyMotion } from "framer-motion";
import { defaultTheme } from "lib/mui";
import { useAppSelector } from "lib/redux";
import dynamic from "next/dynamic";
import { Lato } from "next/font/google";
import Head from "next/head";
import { Router } from "next/router";
import nProgress from "nprogress";
import { ReactNode, useEffect } from "react";
import { usePageType } from "./hooks";
import { selectSharedLayoutContent } from "./store/layoutContentSlice";
import { selectSharedLayoutMessage } from "./store/messageSlice";
import { selectSharedLayoutPopup } from "./store/popupSlice";

// Binding events for loading indicator when navigating.
Router.events.on("routeChangeStart", () => nProgress.start());
Router.events.on("routeChangeComplete", () => nProgress.done());
Router.events.on("routeChangeError", () => nProgress.done());
nProgress.configure({ showSpinner: false }); // Remove spinner

const loadFeatures = () => import("lib/framer-motion/features").then((res) => res.domAnimation);

const DynamicMainLayout = dynamic(() => import("components/main/layout/MainLayout"));
const DynamicAuthLayout = dynamic(() => import("components/auth/layout/AuthLayout"));
const DynamicProfessionalDashboardLayout = dynamic(
  () => import("components/dashboard/professional/layout/ProfessionalDashboardLayout"),
);
const DynamicConsumerDashboardLayout = dynamic(
  () => import("components/dashboard/consumer/layout/ConsumerDashboardLayout"),
);
const DynamicMessage = dynamic(() => import("./Message"));
const DynamicPopup = dynamic(() => import("./Popup"));

const lato = Lato({
  weight: ["400", "700", "900"],
  subsets: ["latin"],
});

interface IProps {
  children: ReactNode;
}

const Layout = ({ children }: IProps) => {
  const { show: shouldShowMessage } = useAppSelector(selectSharedLayoutMessage);
  const { show: shouldShowPopup } = useAppSelector(selectSharedLayoutPopup);
  const sharedLayout = useAppSelector(selectSharedLayoutContent);

  const pageType = usePageType();

  useEffect(() => {
    const tidioChat = document.querySelector<HTMLElement>("#tidio-chat");
    if (!tidioChat) return;
    tidioChat.style.display = pageType !== "main" ? "none" : "initial";
  }, [pageType]);

  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
        <link rel="icon" type="image/png" href="/favicon.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#4d4b2f" />
        <meta name="msapplication-TileColor" content="#4d4b2f" />
        <meta name="theme-color" content="#4d4b2f" />
      </Head>
      <style jsx global>{`
        :root {
          --font-lato: ${lato.style.fontFamily};
        }
      `}</style>
      <CssVarsProvider theme={extendTheme(defaultTheme(sharedLayout))}>
        <LazyMotion features={loadFeatures} strict>
          <AuthProvider>
            {pageType === "auth" ? (
              <DynamicAuthLayout>{children}</DynamicAuthLayout>
            ) : pageType === "professional" ? (
              <DynamicProfessionalDashboardLayout>{children}</DynamicProfessionalDashboardLayout>
            ) : pageType === "buyer-owner" ? (
              <DynamicConsumerDashboardLayout>{children}</DynamicConsumerDashboardLayout>
            ) : (
              <DynamicMainLayout>{children}</DynamicMainLayout>
            )}
          </AuthProvider>
          {shouldShowMessage && <DynamicMessage />}
          {shouldShowPopup && <DynamicPopup />}
        </LazyMotion>
      </CssVarsProvider>
    </>
  );
};

export default Layout;
