import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import omit from "lodash/omit";
import set from "lodash/set";
import { FormField, FormFieldOptions, IFormFieldResponse } from "models/form";

export const formatBytes = (bytes: number, decimals: number) => {
  if (bytes == 0) return "0 Bytes";
  const k = 1024,
    dm = decimals || 2,
    sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

//#region Transform entity response field options to replace key with value
export const transformEntityResponse = <U>(
  obj: object,
  fieldOptionsArray: FormFieldOptions[],
): U => {
  const transformer = (transformObj: typeof obj): object | U => {
    if (Array.isArray(transformObj)) {
      return transformObj.map(transformer) as object;
    } else if (typeof transformObj === "object" && transformObj !== null) {
      let resultObj = cloneDeep(transformObj);

      fieldOptionsArray.forEach((field) => {
        const fieldValue: string | string[] = get(resultObj, field.name);

        if (Array.isArray(fieldValue)) {
          const option = field.options.filter((o) => fieldValue.includes(o.name));
          resultObj = set(resultObj, field.name, option);
        } else if (typeof fieldValue === "string") {
          const option = field.options.find((o) => o.name === fieldValue);
          resultObj = set(resultObj, field.name, option ?? fieldValue);
        }
      });

      return resultObj;
    }

    return transformObj;
  };

  return transformer(obj) as U;
};
//#endregion

//#region Remove data/attributes tags from response
interface IInputData {
  [key: string]: any;
  data?: IInputData | IInputData[];
  attributes?: { [key: string]: any };
}

interface IOutputData {
  [key: string]: any;
}

export const transformResponse = <T>(obj: IInputData | IInputData[]): T =>
  ((): IOutputData | IOutputData[] => {
    if (Array.isArray(obj)) {
      return obj.map(transformResponse);
    } else if (typeof obj === "object" && obj !== null) {
      let newObj: IOutputData = {};
      if (obj.attributes) {
        const { attributes, ...rest } = obj;
        newObj = { ...rest, ...attributes };
      } else if (obj.data) {
        const { data, ...rest } = obj;
        if (Array.isArray(data)) {
          newObj = data.map(transformResponse);
        } else {
          newObj = { ...rest, ...transformResponse(data) };
        }
      } else {
        newObj = Object.fromEntries(
          Object.entries(obj).map(([key, value]) => [key, transformResponse(value)]),
        );
      }
      // Transform nested objects
      for (const key in newObj) {
        if (typeof newObj[key] === "object") {
          newObj[key] = transformResponse(newObj[key]);
        }
      }
      return newObj;
    } else {
      return obj;
    }
  })() as T;
//#endregion

//#region Transform form fields
export const transformForm = <T extends object>(data: T, paths: string[], fieldsKey = "fields") => {
  paths.forEach((path) => {
    const fieldsPath = path + "." + fieldsKey;
    const fields: IFormFieldResponse[] | undefined = get(data, fieldsPath);
    const transformedFields = fields?.map(
      ({ id, field, fieldType: [type] }) =>
        ({
          id,
          ...omit(field, "id"),
          ...omit(type, "id", "__component"),
          type: (() => {
            switch (type.__component) {
              case "form.text":
                return "ComponentFormText";
              case "form.text-area":
                return "ComponentFormTextArea";
              case "form.number":
                return "ComponentFormNumber";
              case "form.auto-complete":
                return "ComponentFormAutoComplete";
              case "form.date-field":
                return "ComponentFormDate";
              case "form.checkbox-group":
                return "ComponentFormCheckboxGroup";
              case "form.radio-group":
                return "ComponentFormRadioGroup";
              case "form.phone-number":
                return "ComponentFormPhoneNumber";
              case "form.otp":
                return "ComponentFormOTP";
              case "form.file-upload":
                return "ComponentFormFileUpload";
            }
          })(),
        }) as FormField,
    );
    set(data, fieldsPath, transformedFields);
  });

  return data;
};
//#endregion
