import { useAppSelector } from "lib/redux";
import { IImage } from "models/shared";
import { NextSeo } from "next-seo";
import { useRouter } from "next/router";
import { selectSharedSeo } from "./store/seoSlice";

const removeLeadingSlash = (slug: string) =>
  slug?.startsWith("/") ? slug.substring(1, slug.length) : slug;

const Seo = () => {
  const {
    siteName,
    url,
    defaultMetaImage,
    twitter,
    // indexing,
    metaTitle,
    metaDescription,
    metaImage: pageMetaImage,
    metaUrl,
  } = useAppSelector(selectSharedSeo);

  const indexing = false; // TODO: remove later

  const { pathname } = useRouter();
  const pageUrl = `${url}/${removeLeadingSlash(metaUrl || pathname)}`;
  const metaImage = pageMetaImage ?? defaultMetaImage;

  return (
    <NextSeo
      title={metaTitle}
      titleTemplate={`%s | ${siteName}`}
      description={metaDescription}
      canonical={pageUrl}
      noindex={!indexing}
      nofollow={!indexing}
      openGraph={{
        type: "website",
        url: pageUrl,
        title: metaTitle,
        description: metaDescription,
        images: metaImage
          ? [
              {
                ...(metaImage.formats?.large ?? metaImage),
                url:
                  process.env.NEXT_PUBLIC_BACKEND_URL +
                  (metaImage.formats?.large?.url ?? metaImage.url),
                alt: (metaImage as IImage).alternativeText,
              },
            ]
          : undefined,
        site_name: siteName,
      }}
      twitter={
        twitter
          ? {
              ...twitter,
              cardType: "summary_large_image",
            }
          : undefined
      }
    />
  );
};

export default Seo;
