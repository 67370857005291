import { combineReducers } from "@reduxjs/toolkit";
import content from "./contentSlice";
import reviewsFilterState from "./reviewsFilterStateSlice";

const reducer = combineReducers({
  content,
  reviewsFilterState,
});

export default reducer;
