import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorResult, isError } from "lib/axios/error";
import { RootState } from "lib/redux";
import { IProfessionalProfileContent } from "models/dashboard/professional/profile";
import { HYDRATE } from "next-redux-wrapper";
import { getProfessionalProfileContentReq } from "rest-api/user/profile";

export const getProfessionalProfileContent = createAsyncThunk<
  typeof initialState,
  void,
  { rejectValue: ErrorResult }
>(
  "dashboard/professional/profile/content/getProfessionalProfileContent",
  async (_, { rejectWithValue }) => {
    const result = await getProfessionalProfileContentReq();

    if (isError(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const selectProfessionalProfileContent = ({ dashboard }: RootState) =>
  dashboard.professional.profile.content;

const initialState: IProfessionalProfileContent = {
  profileSection: {
    completionFields: [],
    items: [],
  },
  aboutSection: {
    title: "",
    values: [],
  },
  detailsSection: {
    title: "",
    values: [],
  },
  reviewsSection: {
    title: "",
    buttons: [],
    allReviews: {
      headerTitle: "",
      title: "",
    },
    filters: [],
  },
  previousWorkSection: {
    title: "",
    viewPreviousWork: {
      headerTitle: "",
    },
  },
};

const contentSlice = createSlice({
  name: "dashboard/professional/profile/content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProfessionalProfileContent.fulfilled, (_, action) => action.payload)
      .addCase(
        HYDRATE,
        (_, action: PayloadAction<IProfessionalProfileContent, typeof HYDRATE>) => action.payload,
      );
  },
});

export default contentSlice.reducer;
