import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorResult, isError } from "lib/axios/error";
import { RootState } from "lib/redux";
import { IConsumerProfileContent } from "models/dashboard/consumer/profile";
import { HYDRATE } from "next-redux-wrapper";
import { getConsumerSettingsContentReq } from "rest-api/user/settings";

export const getConsumerProfileContent = createAsyncThunk<
  typeof initialState,
  void,
  { rejectValue: ErrorResult }
>(
  "dashboard/consumer/profile/content/getConsumerProfileContent",
  async (_, { rejectWithValue }) => {
    const result = await getConsumerSettingsContentReq();

    if (isError(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const selectConsumerProfileContent = ({ dashboard }: RootState) =>
  dashboard.consumer.profile.content;

const initialState: IConsumerProfileContent = {
  title: "",
  infoSection: {
    title: "",
    form: { fields: [], submitButtonText: "" },
  },
  securitySection: {
    title: "",
    form: { fields: [], submitButtonText: "" },
    links: [],
  },
  preferenceSection: {
    title: "",
    options: [],
  },
  accountSection: {
    title: "",
    content: "",
    buttonText: "",
    dialog: {
      title: "",
      content: "",
      buttonText: "",
    },
  },
};

const contentSlice = createSlice({
  name: "dashboard/consumer/profile/content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getConsumerProfileContent.fulfilled, (_, action) => action.payload)
      .addCase(
        HYDRATE,
        (_, action: PayloadAction<IConsumerProfileContent, typeof HYDRATE>) => action.payload,
      );
  },
});

export default contentSlice.reducer;
